<template>
  <div class="flex w-full no-gutter justify-center">
    <a-row style="width: 90%; margin-top: 100px">
      <a-col
        :span="span"
        :offset="offSet"
        style="margin-bottom: 21px; text-align: center"
      >
        <div class="s-register-font1">회원가입 완료</div>

        <div style="text-align: center; margin-bottom: 36px; margin-top: 6px">
          <div class="s-register-font2">
            회원가입을 진심으로 축하드립니다!<br />바로 스토어링크의 다양한
            기능을 사용 해 보세요
          </div>
        </div>
        <div>
          <img
            style="width: 327px"
            src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-signup%402x.png?alt=media&token=b7f70488-c172-4d49-b1d9-02cc3f77ea9b"
          />
        </div>
        <div>
          <a-button
            class="s-register-button1"
            @click="$router.push('/pages/dashboard').catch(() => {})"
          >
            스토어링크 순위관리 바로가기
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { isMobile, replaceAll } from "../components/fnc.js";

export default {
  data() {
    return {
      span: 10,
      offSet: 7
    };
  },
  computed: {
    getPhone() {
      return this.$store.state.AppActiveUser.phone;
    }
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();

      this.span = _mobile ? 24 : 10;
      this.offSet = _mobile ? 0 : 7;

      return _mobile;
    }
  },
  mounted() {
    this.$vs.loading();
    // 회원가입 양식전송 완료
    dataLayer.push({ event: "signup_sent" });
    fbq("track", "CompleteRegistration");

    this.onSendMsg();

    var self = this;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "UPDATE_USER_INFO") {
        self.onSendMsg();
      }
    });
  },
  methods: {
    onSendMsg() {
      var _phone = replaceAll(replaceAll(this.getPhone, "-", ""), " ", "");

      var self = this;

      if (_phone) {
        this.$http
          .post(
            "https://us-central1-storelink-fnc.cloudfunctions.net/onRegNewMemberStoreLink",
            {
              phone: _phone.substr(1)
            }
          )
          .then(function(r) {
            self.$vs.loading.close();
          });
      }
    }
  }
};
</script>

<style>
.s-register-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.11px;
  color: #0264fb;
}

.s-register-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 17px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  text-align: center;
  color: #444444;
}

.s-register-button1 {
  margin-top: 40px;
  color: white;
  width: 334px;
  height: 40px;
  border-radius: 5px;
  background-color: #0264fb;
}
</style>
